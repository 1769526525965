import { Chip, ChipProps } from '@mui/material';
import { AutomatedBackup, Backup } from 'api/backups';
import { useTranslation } from 'react-i18next';

interface BackupStatusCellProps {
  readonly backup: Backup | AutomatedBackup;
}

const formatBackupStatus = (backup: Backup | AutomatedBackup) => {
  switch (backup.status) {
    case 'PROGRESS':
      return 'processing';
    case 'NEW':
      return 'creating';
    case 'ERROR':
      return 'error';
    case 'RESTORING':
      return 'restoring';
    default:
      return 'available';
  }
};

const getStatusColor = (backup: Backup | AutomatedBackup): ChipProps['color'] => {
  switch (backup.status) {
    case 'PROGRESS':
    case 'RESTORING':
      return 'warning';
    case 'NEW':
      return 'primary';
    case 'ERROR':
      return 'error';
    default:
      return 'success';
  }
};

export const BackupStatusCell = ({ backup }: BackupStatusCellProps) => {
  const { t } = useTranslation();

  return (
    <Chip color={getStatusColor(backup)} size="medium" label={t(formatBackupStatus(backup))} />
  );
};

import { Stack } from '@mui/material';
import { AutomatedBackupsTable } from 'component/new_design/partial/tables/backups/AutomatedBackupsTable';
import { ManualBackupsTable } from 'component/new_design/partial/tables/backups/ManualBackupsTable';

interface BackupsProps {
  readonly siteId: string;
}

const Backups = ({ siteId }: BackupsProps) => {
  return (
    <Stack gap={6}>
      <ManualBackupsTable siteId={siteId} />
      <AutomatedBackupsTable siteId={siteId} />
    </Stack>
  );
};

export default Backups;

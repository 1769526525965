import { Radio, RadioProps, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { ReactNode } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';

interface RadioFieldProps extends Omit<RadioProps, 'onChange' | 'onBlur'> {
  readonly name: string;
  readonly label: ReactNode;
  readonly rules?: RegisterOptions;
  readonly helperText?: ReactNode;
}

export const RadioField = ({ name, label, rules, ...rest }: RadioFieldProps) => {
  const {
    field: { ref, value, ...field },
    fieldState: { error },
  } = useController({
    name,
    rules,
    disabled: rest.disabled,
    defaultValue: rest.defaultValue,
  });

  const helperText = error?.message || rest.helperText;

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Radio
            inputRef={ref}
            icon={<span />}
            checkedIcon={<span />}
            {...rest}
            {...field}
            checked={value === rest.value}
          />
        }
        label={label}
      />
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};

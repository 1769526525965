import { useEffect, useId } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRestoreAutomatedBackupDatabase, useRestoreAutomatedBackupFiles } from 'api/backups';
import { useForm } from 'react-hook-form';
import { CheckboxField } from 'component/new_design/base/forms/CheckboxField';
import { RadioField } from 'component/new_design/base/forms/RadioField';
import { FormDialog } from 'component/new_design/base/dialogs/FormDialog';

const ALL_FILES_PATH = 'wp-content';
const PLUGINS_PATH = 'wp-content/plugins';
const THEMES_PATH = 'wp-content/themes';

interface FormValues {
  type: 'all-files' | 'partial-files' | 'database';
  plugins: boolean;
  themes: boolean;
}

interface AutomatedBackupRestoreDialogProps {
  readonly siteId: string;
  readonly backupId: string;
  readonly onClose: () => unknown;
  readonly onSuccess: () => unknown;
}

export const AutomatedBackupRestoreDialog = ({
  siteId,
  backupId,
  onSuccess,
  onClose,
}: AutomatedBackupRestoreDialogProps) => {
  const { t } = useTranslation();

  const methods = useForm<FormValues>({
    defaultValues: {
      type: 'all-files',
      plugins: false,
      themes: false,
    },
  });

  const type = methods.watch('type');

  useEffect(() => {
    if (type !== 'partial-files') {
      methods.setValue('plugins', false);
      methods.setValue('themes', false);
    }
  }, [type, methods]);

  const restoreFiles = useRestoreAutomatedBackupFiles(siteId);
  const restoreDatabase = useRestoreAutomatedBackupDatabase(siteId);

  const isPending = restoreFiles.isPending || restoreDatabase.isPending;

  const onSubmit = async (values: FormValues) => {
    if (values.type === 'database') {
      await restoreDatabase.mutateAsync({
        backupId: String(backupId),
      });
    } else {
      const appliedPaths = [];

      if (values.type === 'all-files') {
        appliedPaths.push(ALL_FILES_PATH);
      } else {
        if (values.plugins) {
          appliedPaths.push(PLUGINS_PATH);
        }
        if (values.themes) {
          appliedPaths.push(THEMES_PATH);
        }
      }

      await restoreFiles.mutateAsync({
        restore_type: 'partial',
        files: appliedPaths,
        backup_id: String(backupId),
      });
    }

    onSuccess();
    onClose();
  };

  return (
    <FormDialog
      form={
        <Stack gap={4}>
          <RadioField name="type" value="all-files" label={t('all_files')} />
          <RadioField name="type" value="partial-files" label={t('partial_files')} />
          <Stack gap={4} ml={4}>
            <CheckboxField
              name="plugins"
              disabled={type !== 'partial-files'}
              label={t('plugin_plural')}
            />
            <CheckboxField
              name="themes"
              disabled={type !== 'partial-files'}
              label={t('theme_plural')}
            />
          </Stack>
          <RadioField name="type" value="database" label={t('database')} />
        </Stack>
      }
      methods={methods}
      onSubmit={onSubmit}
      onClose={isPending ? undefined : onClose}
      title={t('restore_backup')}
      submitText={t('restore')}
    />
  );
};
